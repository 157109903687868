import React from 'react';
import {graphql, navigate} from 'gatsby';
import ReactPaginate from 'react-paginate';
import {Box} from '@chakra-ui/core';
import Layout from '../components/layout';
import NewsList from '../components/NewsList';
import Chevron from '../assets/images/chevron.svg';
import SEO from "../components/SEO";
import {Trans} from "gatsby-plugin-react-i18next";

export default function Blog({data, pageContext}) {
  const seo = {
      title: `Новости - Evors`,
      noindex: false,
      nofollow: false,
  }

  return (
    <>
      <SEO seoData={seo}/>
      <Layout language={pageContext.language}>
        <div className={'container d-flex flex-column'}>
          <h1 className={'news__title'}>
            <Trans>news.title</Trans>
          </h1>
          <NewsList news={data.allWpPost}/>
        </div>

        {pageContext && pageContext.totalPages > 1 && (
          <Box mt={10}>
            <ReactPaginate
              previousLabel={<img src={Chevron} alt={'previous'}/>}
              nextLabel={<img src={Chevron} alt={'next'}/>}
              onPageChange={({selected}) => {
                const page = selected + 1;
                const path = page === 1 ? `/blog/` : `/blog/${page}/`;
                navigate(path);
              }}
              disableInitialCallback
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageContext.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              initialPage={pageContext.page - 1}
            />
          </Box>
        )}
      </Layout>
    </>
  );
}

export const query = graphql`
    fragment Thumbnail on File {
        childImageSharp {
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_tracedSVG
            }
        }
    }
    query Posts($offset: Int!, $perPage: Int!, $languageId: ID!) {
        allWpPost(
            limit: $perPage
            skip: $offset
            filter: { nodeType: { in: ["Post", "Page", "Alot"] }, language: { id: { eq: $languageId } } }
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                content
                title
                uri
                date
                featuredImage {
                    node {
                        remoteFile {
                            ...Thumbnail
                        }
                    }
                }
            }
        }
    }
`;
