import React, {Component} from 'react';
import NewsListItem from '../NewsListItem';

export default class NewsList extends Component {
  render() {
    const {news} = this.props;
    return (
      <div className={'news-container'}>
        <div className={"row"}>
            {news.nodes.map(
                (item, index) => <div className={"col-lg-4 col-md-6 col-sm-6"} key={index}><NewsListItem item={item}/></div>)}
        </div>
      </div>
    );
  }
}
