import React, {Component} from 'react';
import NewsImg from '../../assets/images/news.png';
import Clock from '../../assets/images/clock.svg';
import ArrowWhite from '../../assets/images/arrow-white.svg';
import moment from 'moment';
import {Link} from 'gatsby';
import {Trans} from "gatsby-plugin-react-i18next";

export default class NewsListItem extends Component {
  render() {
    const {item} = this.props;

    const image = item.featuredImage && item.featuredImage.node
      ? item.featuredImage.node.remoteFile.childImageSharp.fluid.srcSet
      : NewsImg;

    return (
      <Link className={'news'} to={item.uri}>
        <div className={'news__overlay'}/>
        <img className={'news__img'} srcSet={image} alt={item.title}/>
        <div className={'news__content'}>
          <h4 className={'news-title'}
              dangerouslySetInnerHTML={{__html: item.title}}/>

          <div dangerouslySetInnerHTML={{
            __html:
              ((item.content).length > 75) ?
                (((item.content).substring(0, 75 - 3)) + '...') :
                item.content
          }}/>
          <div className={'news-footer'}>
                  <span className={'news__date'}>
                    <img className={'ic--date'} src={Clock} alt={'news'}/>
                    {moment(item.date).format('DD MMMM YYYY')}
                  </span>
            <button className={'news__button'}>
              <Trans>link.view</Trans>
              <img className={'ic--arrow'} src={ArrowWhite} alt={'arrow'}/>
            </button>
          </div>
        </div>
      </Link>
    );
  }
}
